import InscreenObserver from '@finn-no/inscreen-observer';
import React, { useEffect, useRef } from 'react';
import Tracking from './tracking';
import { PulseRecommendation } from './types';
let instances = 0;
export const useInstanceId = (
    getInstanceId: (id: number) => string
): string => {
    const ref = useRef<number>(null);
    if (!ref.current) {
        ref.current = ++instances;
    }
    return getInstanceId(ref.current);
};

type TrackInscreenProps = {
    adId: string;
    tracking: Tracking;
    ref: React.MutableRefObject<HTMLDivElement>;
    enabled?: boolean;
    pulseContext?: PulseRecommendation;
};
export const useTrackInscreen = ({
    adId,
    tracking,
    ref,
    enabled = true,
    pulseContext,
}: TrackInscreenProps) => {
    useEffect(() => {
        if (enabled) {
            const observer = new InscreenObserver(
                () => {
                    tracking.trackInscreenWithPulse(pulseContext);
                },
                { once: true }
            );
            observer.observe(ref.current);
            return () => observer.disconnect();
        }
    }, [
        adId,
        ref,
        tracking,
        enabled,
        pulseContext,
    ]);
};

type ExternalClickTrackingProps = {
    adId: string;
    ref: React.MutableRefObject<HTMLDivElement>;
    enabled: boolean;
    tracking: Tracking;
    pulseContext?: PulseRecommendation;
};
export const useExternalClickTracking = ({
    adId,
    ref,
    enabled,
    tracking,
    pulseContext,
}: ExternalClickTrackingProps) => {
    const hovering = useRef(false);
    useEffect(() => {
        if (ref.current) {
            const enterHandler = () => {
                hovering.current = true;
            };
            const leaveHandler = () => {
                hovering.current = false;
            };

            const node = ref.current;
            node.addEventListener('mouseenter', enterHandler, false);
            node.addEventListener('mouseleave', leaveHandler, false);
            node.addEventListener('touchstart', enterHandler, false);
            node.addEventListener('touchend', enterHandler, false);

            return () => {
                node.removeEventListener('mouseenter', enterHandler, false);
                node.removeEventListener('mouseleave', leaveHandler, false);
                node.removeEventListener('touchstart', enterHandler, false);
                node.removeEventListener('touchend', enterHandler, false);
            };
        }
    }, [ref]);
    useEffect(() => {
        if (enabled && adId && ref.current) {
            let trackedClick = false;
            const handler = () => {
                if (hovering.current && !trackedClick) {
                    trackedClick = true;
                    tracking.trackClickWithPulse(pulseContext);
                }
            };
            // The downside to this approach is that
            window.addEventListener('blur', handler);
            window.addEventListener('pagehide', handler);
            window.addEventListener('beforeunload', handler);

            return () => {
                window.removeEventListener('blur', handler);
                window.removeEventListener('pagehide', handler);
                window.removeEventListener('beforeunload', handler);
            };
        }
    }, [
        adId,
        enabled,
        pulseContext,
        ref,
        tracking,
    ]);
};
