import React, { useState } from 'react';
import { Recommendations } from '../../react-recommendations/src';

type RecommendationsWithFallbackProps = {
    fallbackTitle: string;
    fallbackProduct: string;
    fallbackType?: string;
    forceFallback: boolean;
} & React.ComponentProps<typeof Recommendations>;

function isEmpty(str) {
    return !str || 0 === str.length;
}

const RecommendationsWithFallback: React.FC<
    RecommendationsWithFallbackProps
> = ({
    fallbackTitle,
    fallbackProduct,
    fallbackType = 'recommend',
    title,
    product,
    forceFallback,
    ...props
}) => {
    const [fallback, setFallback] = useState(forceFallback);
    const [jobSolrFallback, setJobSolrFallback] = useState(false);

    if (product === 'job' && jobSolrFallback) {
        return (
            <Recommendations
                {...props}
                title={title}
                product='job-solr-coldstart'
                type='mlt'
                useFallBackProduct={true}
            />
        );
    }

    if (fallback && !isEmpty(fallbackProduct)) {
        if (product === 'job') {
            return (
                <>
                    <Recommendations
                        {...props}
                        title={fallbackTitle}
                        product={fallbackProduct}
                        type={fallbackType}
                        useFallBackProduct={true}
                        onEmpty={() => setJobSolrFallback(true)}
                    />
                </>
            );  
        }
        return (
            <>
                <Recommendations
                    {...props}
                    title={fallbackTitle}
                    product={fallbackProduct}
                    type={fallbackType}
                    useFallBackProduct={true}
                />
            </>
        );
    }
    return (
        <Recommendations
            {...props}
            title={title}
            product={product}
            onEmpty={() => setFallback(true)}
        />
    );
};

export default RecommendationsWithFallback;
