import React, { useEffect, useRef, useState } from 'react';
import {
    useExternalClickTracking,
    useInstanceId,
    useTrackInscreen,
} from '../../hooks';
import Tracking from '../../tracking';

declare var FINNExternalDistribution: any;

type BlinkAdProps = {
    ad: import('../../types').Recommendation;
    tracking: Tracking;
    position: string;
};
const BlinkAd: React.FC<BlinkAdProps> = (props) => {
    const { tracking, ad, position } = props;

    const [shouldFallback, setShouldFallback] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const domRef = useRef<HTMLDivElement>(null);
    useTrackInscreen({
        adId: ad.itemId,
        ref: domRef,
        tracking,
        enabled: loaded,
        pulseContext: ad?.tracking?.pulse,
    });
    const instanceId = useInstanceId((id) => `ad-stream__blink-ad-${id}`);
    useEffect(() => {
        if (loaded) {
            // ensure a blink ad is never loaded twice, even in the edge case where the position changed
            return;
        }

        if (
            'FINNExternalDistribution' in window === false ||
            !FINNExternalDistribution.Fixed
        ) {
            setShouldFallback(true);
            return;
        }

        var options = {
            remote: 'https://www.finn.no/distribution-front/external/carousel',
        };

        // eslint-disable-next-line no-undef
        const blinkAd = new FINNExternalDistribution.Fixed(options);

        const blinkOptionsSuccess = {
            selector: `#${instanceId}`,
            position,
            channel: 'recommendation',
            /* TEST CODE
            finncode: '107679348',
            rec: true,
            // */
            limit: 100,
            offset: 50,
            // @TODO probably dynamic later
            distributionTypes: [
                'REALESTATE_LEISURE',
                'REALESTATE',
                'REALESTATE_PROJECT',
                'CAR',
            ],
        };

        blinkAd.inject(blinkOptionsSuccess, (err, result) => {
            if (err) {
                setShouldFallback(true);
            }
            if (result) {
                setLoaded(true);
            }
        });
    }, [instanceId, loaded, position]);

    useExternalClickTracking({
        adId: ad.itemId,
        ref: domRef,
        enabled: loaded,
        tracking,
        pulseContext: ad?.tracking?.pulse,
    });

    if (shouldFallback) {
        return null;
    }

    return (
        <div aria-hidden="true">
            <div id={instanceId} className="h-full" ref={domRef} />
        </div>
    );
};

export default BlinkAd;
