import React, { useEffect } from 'react';

type TrackEmptyProps = {
    onEmpty?: () => void;
};
const TrackEmpty: React.FC<TrackEmptyProps> = ({ onEmpty }) => {
    useEffect(() => {
        if (onEmpty) onEmpty();
    }, [onEmpty]);

    return null;
};

export default TrackEmpty;
