import React, { useRef } from 'react';
import { Recommendation } from '../../types'
import { useTrackInscreen } from '../../hooks';
import Tracking from '../../tracking';
import { IconTorgetShipping24 } from '@fabric-ds/icons/react';
import { Clickable } from '@fabric-ds/react';
import ImageFormat from './ImageFormat';
import { troikaStyles, classNames } from './sharedStylings';

type AdProps = {
    item: Recommendation;
    imagesCdnUrl: string;
    tracking: Tracking;
    favoriteHeart: (itemId: string) => React.ReactNode;
}

const StandardAd = (props: AdProps) => {
    const {
        item,
        imagesCdnUrl,
        tracking,
        favoriteHeart,
    } = props;

    const domRef = useRef<HTMLDivElement>(null)

    useTrackInscreen({
        adId: item.itemId,
        tracking,
        enabled: true,
        ref: domRef,
        pulseContext: item?.tracking?.pulse,
    });

    const handleAdClick = () => {
        tracking.trackClickWithPulse(item.tracking.pulse);
    };

    const shippingLabel = item.shippingOption?.label;
    const p4pLabel = item.overlay?.p4p?.label;
    const easyapplyLabel = item.overlay?.easyapply?.label;

    return (
        <article aria-owns={`rec-ad-${item.itemId}`} ref={domRef} className={classNames.article}>
            {/* image */}
            <div className={classNames.imageWrapper}>
                <ImageFormat
                    imageUrl={item.image.url}
                    type={item.image.type}
                    isAbsolute={item.image.isAbsolute}
                    imagesCdnUrl={imagesCdnUrl} />
                {/* label */}
                {item.label && item.image?.type !== 'LOGO' && (
                    <span>
                        <div className={`${classNames.label}`} style={troikaStyles.shadowOverlay}
                            title="Legg til favoritt">{item.label}
                        </div>
                    </span>
                )}
            </div>

            {/* location */}
            <div className={classNames.location}>
                {item.location && (
                    <span className={classNames.locationInner}>{item.location.combined}</span>
                )}
            </div>

            {/* heading */}
            <h2 className={classNames.heading} id={item.itemId}>
                <Clickable
                    href={`/${item.itemId}`}
                    className={`${classNames.headingInner}`}
                    id={item.itemId}
                    onClick={handleAdClick}>
                    {item.heading}
                </Clickable>
            </h2>

            {/* company and custom labels */}
            <div className="flex items-end justify-between text-12">
                {
                    item.content?.company_name && (
                        <div>
                            {item.content?.company_name}
                        </div>
                    )
                }
                {
                    item.customLabel && (
                        <div>
                            {item.customLabel}
                        </div>
                    )
                }
            </div>

            {/* favorite heart */}
            <div className={classNames.favoriteHeart}>{favoriteHeart(item.itemId)}</div>

            {/* labels  */}
            {
                shippingLabel &&
                (
                    <div className={classNames.shippingLabel}>
                        <IconTorgetShipping24 className={classNames.iconTorget} />
                        {shippingLabel}
                    </div>
                )
            }
            {
                easyapplyLabel &&
                (
                    //if shipping label is there, move label down
                    <p className={`${classNames.easyApplyLabel} ${shippingLabel ? 'top-40 left-10' : 'top-10 left-10'}`}>{easyapplyLabel}</p>

                )
            }
            {
                p4pLabel &&
                (
                    //if shipping label is there, move label down
                    <p className={`${classNames.phpLabel} ${shippingLabel ? 'top-40 left-10' : 'top-10 left-10'}`}>Betalt plassering</p>

                )
            }
            {/* focus ancor point for tabfocus */}
            <div className={classNames.focusAncor}></div>
        </article >
    );
};

export default StandardAd;