export const classNames = {
    article: 'cursor-pointer relative overflow-hidden transition-all outline-none recommendation-ad rounded-8 p-10 hover:bg-aqua-50 focus:bg-aqua-50',
    imageWrapper: 'aspect-w-1 aspect-h-1 overflow-hidden rounded-8 border border-bluegray-300',
    image: 'w-full h-full object-center object-cover',
    label: 'absolute bottom-0 font-bold text-white py-10 pl-10 pr-16',
    location: 'text-12 py-6',
    locationInner: 'text-gray-500',
    heading: 'h4 mb-0 mb-8 pr-44 break-words',
    headingInner: 'recommendation-ad-link link link--dark hover:no-underline recommendation-heading-line-clamp',
    customLabel: 'text-12 py-6 mb-4',
    favoriteHeart: 'absolute top-16 right-16',
    shippingLabel: 'absolute top-10 left-10 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 text-yellow-800 bg-yellow-100',
    iconTorget: 'w-16 h-16 mr-4',
    phpLabel: 'absolute inline-flex bg-aqua-200 text-bluegray-600 text-12 p-4 rounded-4 border-bluegray-300',
    easyApplyLabel: 'absolute inline-flex bg-green-100 text-12 text-green-800 p-4 rounded-4 border-green-100',
    focusAncor: 'absolute inset-0 z-10 pointer-events-none outline-none rounded-8 border-2 border-transparent transition-all recommendation-ad-outline'
};

//shadow overlay troika css leftover, will be removed once fabric solution is found
export const troikaStyles = {
    shadowOverlay: {
        background: `
      linear-gradient(
        transparent,
        rgba(0, 0, 0, 0.04935) 13.71%,
        rgba(0, 0, 0, 0.11481) 25.12%,
        rgba(0, 0, 0, 0.1925) 34.61%,
        rgba(0, 0, 0, 0.27852) 42.59%,
        rgba(0, 0, 0, 0.36898) 49.46%,
        rgba(0, 0, 0, 0.46) 55.63%,
        rgba(0, 0, 0, 0.54769) 61.47%,
        rgba(0, 0, 0, 0.62815) 67.41%,
        rgba(0, 0, 0, 0.6975) 73.83%,
        rgba(0, 0, 0, 0.75185) 81.13%,
        rgba(0, 0, 0, 0.78731) 89.73%,
        rgba(0, 0, 0, 0.8))`,
        width: '100%',
    }
}