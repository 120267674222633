import React, { useRef } from 'react';
import { Card, Clickable } from '@fabric-ds/react';
import { ribbon } from '@fabric-ds/component-classes';
import { Recommendation } from '../../types'
import { useTrackInscreen } from '../../hooks';
import Tracking from '../../tracking';
import ImageFormat from './ImageFormat';

type AdProps = {
    item: Recommendation;
    tracking: Tracking;
    isOnJobPage: boolean;
}

const CmsArticle = (props: AdProps) => {
    const {
        item,
        tracking,
        isOnJobPage
    } = props;

    const domRef = useRef<HTMLDivElement>(null);

    useTrackInscreen({
        adId: item.itemId,
        tracking,
        enabled: true,
        ref: domRef,
        pulseContext: item?.tracking?.pulse,
    });

    return (
        <div ref={domRef} className="hover:bg-aqua-50 rounded-8 p-10 focus:ring-2">
            <Card flat={true} className="border-none shadow-none h-full rounded-8">
                <div aria-owns={item.itemId}></div>
                <div className={`overflow-hidden border border-b-0 rounded-0 ${isOnJobPage ? 'aspect-w-2 aspect-h-1' : 'aspect-w-1 aspect-h-1'}`}>
                    <ImageFormat
                        imageUrl={item.image.url}
                        type={item.image.type}
                        isAbsolute={item.image.isAbsolute} />
                </div>
                <h4 className="hover:bg-aqua-50 text-gray-700 mb-16 h-full py-6" id={item.itemId}>
                    <Clickable
                        href={item.itemId}
                        className="text-current hover:no-underline focus:no-underline"
                        onClick={() => {
                            tracking.trackClickWithPulse(item.tracking.pulse)
                        }}
                    >
                        {item.heading}
                    </Clickable>
                </h4>
                <span
                    className={`${ribbon.base} ${ribbon.sponsored} absolute text-12 top-12 left-12`}
                >Nyttige artikler</span>
            </Card>
        </div>
    );
};

export default CmsArticle;
