import { DeviceType } from '@finn-no/displayads-client';
import InscreenObserver from '@finn-no/inscreen-observer';
import { MessageBus } from '@podium/browser';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Except } from 'type-fest';
import Ads from '../components/ad/Ads'
import Ad from '../components/ad/Ad';
import BlinkAd from '../components/ad/BlinkAd';
import Tracking from '../tracking';
import Fetcher from './fetcher';
import TrackEmpty from '../components/TrackEmpty';

type RecommendationsResponse = import('../types').RecommendationsResponse;

type RecommendationsStreamProps = {
    baseUrl: string;
    url?: string;
    product: string;
    rows: string;
    singleColumn?: boolean;
    numColumns: number;
    disableLoadMoreButton?: boolean;
    pages: string;
    type: string;
    adId: string;
    fetch?: typeof window.fetch;
    position: string;
    title: string;
    debug?: boolean;
    deviceType: DeviceType;
    useFallBackProduct?: boolean;
    suppressTitle: boolean;
    previewId?: string;
    previewType?: string;
    onEmpty?: () => void;
} & Except<React.ComponentProps<typeof Ad>, 'tracking'>
    & Except<React.ComponentProps<typeof BlinkAd>, 'position'>;

const RecommendationsStream: React.FC<RecommendationsStreamProps> = (props) => {
    const {
        fetch,
        baseUrl,
        rows,
        singleColumn,
        numColumns,
        disableLoadMoreButton,
        pages,
        type,
        adId,
        title,
        onEmpty,
        url,
        debug,
        useFallBackProduct,
        previewId,
        previewType,
        suppressTitle,
        ...passThroughProps
    } = props;

    const { product, position, deviceType } = props;
    const isFrontpage = position.split('_')[0] === 'm-frontpage';
    const isFrontpagePersonal = product === 'frontpage-personal';
    const isBapMarkedFrontpage = position.split('_')[0] === 'bap-browse';
    const usePadding = (product !== 'bap' || isBapMarkedFrontpage);
    const isRealestateSold = type === 'mlt' && product === 'real-estate-sold';

    const [state, setState] = useState({
        items: [],
        isEmpty: false,
        hasContent: false,
        isLoading: true,
        uuid: '',
        version: '',
        trackingContext: null,
        shouldFetchMore: false,
        fetchRestOnClick: false, // Behind unleash
        bottomReached: false,
        clickIsActive: false,
        reRender: false,
        fallbackEmpty: false,
    });
    const { uuid, version, trackingContext } = state;
    const messageBus = new MessageBus();

    const tracking = useMemo(
        () =>
            new Tracking({
                position,
                uuid,
                version,
                deviceType,
                trackingContext,
            }),
        [position, uuid, version, deviceType, trackingContext]
    );

    const fetcher = useMemo(() => new Fetcher(props.fetch), [props.fetch]);

    const fetchMoreItems = useCallback(() => {
        setState((state) => ({
            ...state,
            isLoading: true,
        }));

        return fetcher
            .getFetchMore(baseUrl)
            .then((data) => {
                let nextState = {
                    isLoading: false,
                };

                setState((state) => ({
                    ...state,
                    ...nextState,
                    hasContent: true,
                    isEmpty: false,
                    shouldFetchMore: data.items.length > 0,
                    items: [...state.items, ...data.items],
                    clickIsActive: false,
                }));
            })
            .catch((error) => {
                setState((state) => ({ ...state, isLoading: false }));
                console.error(error);
            });
    }, [baseUrl, fetcher]);

    const scrollFooterEle = useRef<HTMLDivElement>(null);
    const scrollObserver = useRef(null);
    const loadMoreBtn = useRef(null);
    const rowsToRender = parseInt(pages) * parseInt(rows);
    const bottomOut = 'bottom_out';
    const clickCounter = 'load_more_clicked';

    const incrementBottomOut = useCallback(
        (isAbsoluteBottom) => {
            sendMetrics(
                `${url}/tracking/${bottomOut}/${isAbsoluteBottom}/${position}?ts=${new Date().getTime()}`
            );
        },
        [position, url]
    );

    const incrementCounter = useCallback(
        (label = 'unknown') => {
            sendMetrics(
                `${url}/tracking/${label}/${position}?ts=${new Date().getTime()}`
            );
        },
        [position, url]
    );
    useEffect(() => {
        if (
            !state.fetchRestOnClick &&
            state.items.length >= rowsToRender &&
            type === 'recommend'
        ) {
            if (loadMoreBtn.current)
                loadMoreBtn.current.parentNode.classList.remove('hidden');
            return;
        }

        if (
            state.fetchRestOnClick &&
            state.items.length === rowsToRender &&
            !state.bottomReached
        ) {
            setState((state) => ({
                ...state,
                bottomReached: true,
            }));
            incrementBottomOut(true);
        }
        if (
            !scrollObserver.current &&
            scrollFooterEle.current &&
            state.shouldFetchMore &&
            state.items.length > 0 &&
            state.clickIsActive === false
        ) {
            scrollObserver.current = new InscreenObserver(
                () => fetchMoreItems(),
                { proximity: 200, once: true }
            );
            scrollObserver.current.observe(scrollFooterEle.current);
            return () => {
                scrollObserver.current.disconnect();
                scrollObserver.current = null;
            };
        }
    }, [
        fetchMoreItems,
        state.items.length,
        state.shouldFetchMore,
        rowsToRender,
        state.fetchRestOnClick,
        state.bottomReached,
        incrementBottomOut,
        state.clickIsActive,
        type,
    ]);

    useEffect(() => {
        if (loadMoreBtn.current) {
            const fetchMoreBtnInscreen = new InscreenObserver(
                () => incrementBottomOut(false),
                { proximity: 0, once: true }
            );
            fetchMoreBtnInscreen.observe(loadMoreBtn.current);
        }
    }, [incrementBottomOut]);

    useEffect(() => {
        let mounted = true;

        setState((state) => ({
            ...state,
            items: [],
            isLoading: true,
            shouldFetchMore: false,
        }));

        fetcher
            .getRecommendations(baseUrl, {
                product: product,
                rows,
                pages,
                type,
                adId,
                position,
                debug,
                previewId,
                previewType,
            })
            .then((data) => {
                if (previewId.length > 0) {
                    return fetcher
                        .getPreview(baseUrl, previewId, previewType)
                        .then((preview): Promise<RecommendationsResponse> => {
                            return new Promise((res) => {
                                if (preview.items) {
                                    data.items.splice(
                                        0,
                                        preview.items.length,
                                        ...preview.items
                                    );
                                }
                                return res(data);
                            });
                        });
                } else {
                    return Promise.resolve(data);
                }
            })
            .then((data) => {
                if (!mounted) {
                    // Fix "React can't run state updates on an unmounted component" warnings
                    return;
                }
                let nextState = {
                    isLoading: false,
                    fallbackEmpty: (type === 'recommend' && product === 'job' && data.items.length === 0),
                };

                if (data.items.length === 0) {
                    setState((state) => ({
                        ...state,
                        ...nextState,
                        isEmpty: state.items.length === 0,
                        reRender: false,
                    }));

                    return data;
                }

                setState((state) => ({
                    ...state,
                    ...nextState,
                    hasContent: true,
                    isEmpty: false,
                    uuid: data.uuid,
                    trackingContext: data['tracking-context'],
                    version: data.version,
                    items: data.items,
                    shouldFetchMore: true,
                    reRender: false,
                }));
            })
            .catch((error) => {
                if (!mounted) {
                    // Fix "React can't run state updates on an unmounted component" warnings
                    return;
                }

                setState((state) => ({
                    ...state,
                    isLoading: false,
                    reRender: false,
                }));
                console.error(error);
            });

        return () => {
            mounted = false;
        };
    }, [
        adId,
        baseUrl,
        fetcher,
        pages,
        product,
        rows,
        type,
        position,
        debug,
        useFallBackProduct,
        isFrontpage,
        state.reRender,
        previewType,
        previewId,
        suppressTitle,
        isBapMarkedFrontpage,
    ]);

    const { isLoading, isEmpty, hasContent } = state;
    const nothingToRender = !isLoading && isEmpty;
    
    if (nothingToRender) {
        // Special handeling for job mlt fallback chain
        if (position === 'job-object_v2ads' && useFallBackProduct) {
            if (state.fallbackEmpty) {
                return <TrackEmpty onEmpty={onEmpty} />;
            }
        } else if (product !== 'job-solr-coldstart') {
            return <TrackEmpty onEmpty={onEmpty} />;
        }
    }

    function getMoreOnClick() {
        setState((state) => ({
            ...state,
            shouldFetchMore: true,
            fetchRestOnClick: true,
            clickIsActive: true,
        }));
        fetchMoreItems();

        if (loadMoreBtn.current) {
            loadMoreBtn.current.parentNode.classList.add('hidden');
        }
        incrementCounter(clickCounter);
    }

    messageBus.subscribe(
        'recommendations-podlet',
        'reload-recommendations',
        () => {
            setState((state) => ({
                ...state,
                shouldFetchMore: false,
                fetchRestOnClick: false,
                items: [],
                reRender: true,
                // hasContent: false
            }));
        }
    );

    return (
        <section aria-labelledby={title}>
            {hasContent && (
                <div
                    style={{ alignItems: 'center', display: 'flex' }}
                    className={`grid grid--auto-height grid--vertical-center ${usePadding ? 'm-16' : ''}`}
                >
                    {!suppressTitle && (
                        <h1 className="grid__unit text-22 inline pr-8">
                            {title}
                        </h1>
                    )}
                    {isFrontpagePersonal && (
                        <a
                            href="/anbefalinger/historikk"
                            className="link text-14"
                        >
                            Hvorfor anbefaler vi disse annonsene?
                        </a>
                    )}
                </div>
            )}
            <Ads
                items={state.items}
                tracking={tracking}
                numColumns={singleColumn ? 1 : numColumns}
                usePadding={usePadding}
                {...passThroughProps}
            />
            <div ref={scrollFooterEle} />

            {state.isLoading && <div className="f-spinner mx-auto" />}

            {(type === 'recommend' && product !== 'blink-preview' && !disableLoadMoreButton) ||
                isRealestateSold ? (
                <div className="hidden text-center">
                    <button
                        className="button button--cta mb-16"
                        onClick={getMoreOnClick}
                        onKeyDown={(e) => {
                            getMoreOnClick();

                            // focus the last element in the ads array before appending more items
                            if (loadMoreBtn.current) {
                                const lastAd =
                                    loadMoreBtn.current?.parentNode.parentNode
                                        .querySelector('.ads')
                                        .lastChild.querySelector('a');
                                lastAd.focus();
                            }
                        }}
                        ref={loadMoreBtn}
                    >
                        {!isRealestateSold
                            ? ' Se flere anbefalinger'
                            : 'Se flere boliger'}
                    </button>
                </div>
            ) : null}
        </section>
    );
};

function sendMetrics(url) {
    try {
        fetch(url);
    } catch (err) {
        console.log(err);
    }
}

export default RecommendationsStream;
