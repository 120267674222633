import React, { useRef } from 'react';
import { ribbon } from '@fabric-ds/component-classes';

import { Recommendation } from '../../types'
import { useTrackInscreen } from '../../hooks';
import Tracking from '../../tracking';

import {
    Card,
    Clickable,
} from '@fabric-ds/react';

type AdProps = {
    item: Recommendation;
    imagesCdnUrl: string;
    tracking: Tracking;
    favoriteHeart: (itemId: string) => React.ReactNode;
}

const JobAd = (props: AdProps) => {
    const {
        item,
        imagesCdnUrl,
        tracking,
        favoriteHeart,
    } = props;

    const domRef = useRef<HTMLDivElement>(null)

    useTrackInscreen({
        adId: item.itemId,
        tracking,
        enabled: true,
        ref: domRef,
        pulseContext: item?.tracking?.pulse,
    });

    return (
        <div ref={domRef} className="hover:bg-aqua-50 rounded-8 p-10 focus:ring-2">
            <Card flat={true} className="border shadow-none border-gray-300 hover:border-gray-300 h-full">
                <div aria-owns={item.itemId} />
                <div className="flex flex-col h-full">
                    <div className="aspect-w-2 aspect-h-1 bg-white">
                        <img
                            alt=""
                            className="w-full h-full object-center object-contain m-auto"
                            src={`${imagesCdnUrl}/dynamic/default/${item.image.url}`}
                            style={{ maxWidth: '65%', maxHeight: '65%' }}
                        />
                    </div>
                    <div className="flex flex-col content-between bg-bluegray-100 h-full">
                        <h4 className="text-gray-700 p-6 h-full" id={item.itemId}>
                            <Clickable
                                href={`/${item.itemId}`}
                                className="text-current hover:no-underline focus:no-underline"
                                id={item.itemId}
                                onClick={() => {
                                    tracking.trackClickWithPulse(item.tracking.pulse)
                                }}
                            >
                                {item.heading}
                            </Clickable>
                        </h4>
                        <div className="flex items-end justify-between p-6 text-12">
                            <div>
                                {item.content?.company_name}
                            </div>
                            <div>
                                {item.location?.combined} • {item.content.published_relative}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute top-6 right-6">
                    {favoriteHeart(item.itemId)}
                </div>
                {
                    item.overlay?.easyapply?.label &&
                    (
                        <span
                            className={`${ribbon.base} ${ribbon.success} absolute text-12 top-12 left-12`}
                        >Enkel søknad</span>
                    )
                }
                {
                    item.overlay?.p4p?.label &&
                    (
                        <span
                            className={`${ribbon.base} ${ribbon.sponsored} absolute text-12 top-12 left-12`}
                        >Betalt plassering</span>
                    )
                }
            </Card>
        </div>
    );
};

export default JobAd;
