import pulse from '@finn-no/pulse-sdk';
import { PulseRecommendation } from './types';

export default class Tracking {
    position: string;
    uuid: string;
    version: string;
    enabled: boolean;
    deviceType: string;
    trackInscreenIds: any[];
    trackInscreenTask?: Promise<void>;
    trackInscreenWithUrl: string[];
    trackingContext: {};

    readonly trackingDetailProperty;

    constructor({
        position = '',
        uuid = '',
        version = '',
        deviceType = null,
        trackingContext = null,
    }: {
        position?: string;
        uuid?: string;
        version?: string;
        deviceType?: string;
        trackingContext?: {};
    } = {}) {
        this.position = position;
        this.uuid = uuid;
        this.version = version;
        this.enabled = !!position;
        this.trackInscreenIds = [];
        this.deviceType = deviceType;
        this.trackingDetailProperty = 'personalized-ads';
        this.trackingContext = trackingContext;
    }

    trackItemToPulseBasedOnContext(
        itemContext: {},
        keyWord: 'Click' | 'View',
        trackingContext: {}
    ): {} | undefined {
        if (!this.enabled) {
            console.error(`ignored ${keyWord} tracking to Pulse`, ...arguments);
            return;
        }
        if (!itemContext || !trackingContext) return;
        for (const [key] of Object.entries(itemContext)) {
            const event = itemContext[key].find(
                (event) => event['type'] === keyWord
            );
            const ctxValue = trackingContext[key];
            if (event && ctxValue) {
                event[key] = ctxValue;
                pulse.trackEvent(event, {disableAddClassifiedAdToItems: true});
            }
        }
    }

    trackClickWithPulse(item: PulseRecommendation) {
        this.trackItemToPulseBasedOnContext(item, 'Click', this.trackingContext);
    }

    trackInscreenWithPulse(item: PulseRecommendation) {
        this.trackItemToPulseBasedOnContext(item, 'View', this.trackingContext);
    }
}
