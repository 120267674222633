// import { TagRecs } from '../types';

const FETCH_OPTIONS: RequestInit = {
    credentials: 'include',
    headers: {
        accept: 'application/json',
    },
    cache: 'no-store',
};

type RecommendationsResponse = import('../types').RecommendationsResponse;

type FetchMoreResponse = {
    items: import('../types').Recommendation[];
};

export default class Fetcher {
    fetchMore: string[];
    version: string;
    uuid: string;
    fetchMoreIdx: number;
    fetch: typeof window.fetch;
    clicked: boolean;
    constructor(fetch) {
        this.fetch = fetch || window.fetch.bind(window);
        this.fetchMore = null;
        this.version = null;
        this.uuid = null;
        this.fetchMoreIdx = 0;
        this.clicked = false;
    }

    static parseResponseOrThrow(response: Response) {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(
            new Error(`${response.status} ${response.statusText}`)
        );
    }

    static buildUrl(
        baseUrl: string,
        { type = '', product = '', adId = '', rows, pages, position, debug }
    ) {
        const rowsPerPage = rows;
        const rowsTotal = rows * pages;
        if (debug === 'true') {
            return `${baseUrl}/debug/${type}/${product}/${adId}?rows=198&render=6`;
        }
        if (type === 'recommend' && product === 'history') {
            return `${baseUrl}/${type}/${product}?pos=${position}&rows=13`;
        }
        if (type === 'mlt') {
            return `${baseUrl}/${type}/${product}/${adId}?rows=${rowsTotal}&render=${rowsPerPage}&pos=${position}`;
        }
        return `${baseUrl}/${type || 'recommend'}/${product}?rows=${
            product === 'blink-preview' ? rowsTotal : '200'
        }&render=${rowsPerPage}&pos=${position}`;
    }

    getRecommendations(baseUrl, query): Promise<RecommendationsResponse> {
        return this.fetch(Fetcher.buildUrl(baseUrl, query), FETCH_OPTIONS)
            .then(Fetcher.parseResponseOrThrow)
            .then((recommendations: RecommendationsResponse) => {
                this.fetchMore = recommendations.fetchMore;
                this.version = recommendations.version;
                this.uuid = recommendations.uuid;
                return recommendations;
            });
    }

    getPreview(baseUrl: string, previewId: string, previewType: string) {
        return this.fetch(
            Fetcher.buildPreviewUrl(baseUrl, previewId, previewType),
            FETCH_OPTIONS
        )
            .then(Fetcher.parseResponseOrThrow)
            .then((recommendations: RecommendationsResponse) => {
                if (
                    previewType === 'blink' &&
                    recommendations.items.length === 0
                ) {
                    return this.getPreview(baseUrl, previewId, 'blink-preview');
                } else {
                    return recommendations;
                }
            });
    }

    buildFetchMoreUrl(baseUrl: string, version: string) {
        return `${baseUrl}/${
            this.fetchMore[this.fetchMoreIdx]
        }&recommenderId=${version}`;
    }
    static buildPreviewUrl(
        baseUrl: string,
        previewId: string,
        previewType: string
    ) {
        if (previewId.length > 0) {
            if (previewType === 'blink-preview') {
                return `${baseUrl}/blink-preview/${previewId}`;
            } else {
                return `${baseUrl}/preview/${previewType}/${previewId}`;
            }
        }
        return null;
    }

    getFetchMore(baseUrl): Promise<FetchMoreResponse> {
        if (this.fetchMoreIdx < this.fetchMore.length) {
            return this.fetch(
                this.buildFetchMoreUrl(baseUrl, this.version),
                FETCH_OPTIONS
            )
                .then(Fetcher.parseResponseOrThrow)
                .then((recommendations: FetchMoreResponse) => {
                    this.fetchMoreIdx++;
                    return {
                        ...recommendations,
                        version: this.version,
                        uuid: this.uuid,
                    };
                });
        } else {
            return Promise.resolve({
                items: [],
            });
        }
    }
}
