import React from 'react';

import CmsArticle from './CmsArticle';
import JobAd from './JobAd';
import StandardAd from './Ad';
import NativeAd from './NativeAd';
import BannerAd from './BannerAd';
import GoogleAd from './GoogleAd';
import BlinkAd from './BlinkAd';
import { Recommendation } from '../../types'
import Tracking from '../../tracking';

function gridStyle(numColumns: number, usePadding: boolean) {
    if (numColumns === 1) {
        return 'flex flex-col';
    }
    return `grid f-grid grid-flow-row-dense grid-cols-2 md:grid-cols-${numColumns - 1} lg:grid-cols-${numColumns} ${usePadding ? 'mx-10' : ''}`;
}

type AdsProps = {
    items: Recommendation[];
    imagesCdnUrl: string;
    tracking: Tracking;
    favoriteHeart: (itemId: string) => React.ReactNode;
    position: string;
    numColumns: number;
    usePadding: boolean;
}

const Ads: React.FC<AdsProps> = (props) => {
    const {
        items,
        imagesCdnUrl,
        tracking,
        favoriteHeart,
        position,
        numColumns,
        usePadding,
    } = props;

    const isJobAd = ['job-object', 'job-browse'].includes(position.split('_')[0]);

    return (
        <div className={gridStyle(numColumns, usePadding)}>
            {items.map((item, index) => {
                switch (item.renderType) {
                    case "CMS":
                        return (
                            <CmsArticle
                                item={item}
                                tracking={tracking}
                                isOnJobPage={isJobAd}
                            />
                        );
                    case "NATIVE_AD":
                        return (
                            <NativeAd
                                key={`${position}-${item.itemId || `inject-${index}`}`}
                                ad={item}
                                tracking={tracking}
                                position={position}
                            />
                        );

                    case "BANNER_AD":
                        return (
                            <BannerAd
                                key={`${position}-${item.itemId || `inject-${index}`}`}
                                ad={item}
                                tracking={tracking}
                                position={position}
                            />
                        );
                    case "EXTERNAL_AD":
                        return (
                            <GoogleAd
                                key={`${position}-${item.itemId || `inject-${index}`}`}
                                ad={item}
                                tracking={tracking}
                                position={position}
                            />
                        );
                    case "BLINK":
                        return (
                            <BlinkAd
                                key={`${position}-${item.itemId || `inject-${index}`}`}
                                ad={item}
                                tracking={tracking}
                                position={position}
                            />
                        );
                    default:
                        if (isJobAd) {
                            return (
                                <JobAd
                                    item={item}
                                    imagesCdnUrl={imagesCdnUrl}
                                    tracking={tracking}
                                    favoriteHeart={favoriteHeart}
                                />
                            );
                        }

                        return (
                            <StandardAd
                                item={item}
                                imagesCdnUrl={imagesCdnUrl}
                                tracking={tracking}
                                favoriteHeart={favoriteHeart}
                            />
                        );
                }
            })}
        </div>
    );
};

export default Ads;
