import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    useExternalClickTracking,
    useInstanceId,
    useTrackInscreen,
} from '../../hooks';
import Tracking from '../../tracking';
import { MessageBus } from '@podium/browser';

let messageBus

type GoogleAdProps = {
    ad: import('../../types').Recommendation;
    tracking: Tracking;
    position: string;
};

const MB_CHANNEL_GOOGLE_AD = 'recirc-google-ad'

const GoogleAd: React.FC<GoogleAdProps> = (props) => {
    const { tracking, ad, position } = props;

    const [loaded, setLoaded] = useState(false);
    const [loadFailed, setLoadFailed] = useState(false);

    const domRef = useRef<HTMLDivElement>(null);


    const handleLoadFailed = useCallback(() => {
        setLoadFailed(true)
    }, [])

    const instanceId = useInstanceId((id) => `ad-stream__google-ad-${id}`);

    useTrackInscreen({
        adId: ad.itemId,
        ref: domRef,
        tracking,
        enabled: loaded,
        pulseContext: ad?.tracking?.pulse,
    });

    useExternalClickTracking({
        adId: ad.itemId,
        ref: domRef,
        enabled: loaded,
        tracking,
        pulseContext: ad?.tracking?.pulse,
    });

    useEffect(() => {
        const hasPayloadData = instanceId && ad?.content?.description && position;
        if (!hasPayloadData) return;
        if (!domRef.current) return

        const size = domRef.current.getBoundingClientRect();

        const payloadData = {
            instanceId,
            query: ad.content.description,
            position,
            size
        }

        if (!messageBus) {
            messageBus = new MessageBus();
        }

        if (!loaded) {
            messageBus.subscribe(MB_CHANNEL_GOOGLE_AD, 'load-failed', handleLoadFailed);
            messageBus.publish(MB_CHANNEL_GOOGLE_AD, 'init', payloadData)
            setLoaded(loaded)
        }

        return () => {
            if (!messageBus) {
                messageBus = new MessageBus();
            }

            messageBus.unsubscribe(MB_CHANNEL_GOOGLE_AD, 'load-failed', handleLoadFailed);
        }
    }, [instanceId, ad, position, domRef, loaded, handleLoadFailed]);

    if (loadFailed) return null;

    return (
        <div aria-hidden="true" className="h-full ads__unit p-0" ref={domRef}>
            <slot name={instanceId} />
        </div>
    );
};

export default GoogleAd;
