function hasLegalBasis(baseUrl): Promise<boolean> {
    return window
        .fetch(`${baseUrl}/haslegalbasis`, {
            credentials: 'same-origin',
            headers: { accept: 'application/json' },
        })
        .then((res) => {
            if (!res.ok) {
                throw new Error('not ok');
            }
            return res;
        })
        .then((response) => response.json())
        .then((json) => json.hasLegalBasis)
        .catch(() => true);
}

export default hasLegalBasis;
