import createHeartComponent from '@finn-no/favorite-heart-component';
import React from 'react';
import ReactDOM from 'react-dom';
import RecommendationsWithFallback from '../../podlet-common/src/RecommendationsWithFallback';
import hasLegalBasis from '../../podlet-common/src/legalBasis';
import { LitElement, html } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';

class WebComponent extends LitElement {
    appRef = createRef();
    initialState: any;

    static get properties() {
        return {
            initialState: { type: Object, attribute: 'initial-state' },
        };
    }

    render() {
        return html`
            <link
                rel="stylesheet"
                type="text/css"
                href="${this.initialState.css}"
            />
            <section
                data-nosnippet
                aria-label="Anbefalinger"
                id="${this.initialState.type}-podlet-root"
                ${ref(this.appRef)}
            ></section>
            ${this.initialState.disableBlinkScript
                ? html``
                : html`<script src="https://static.finncdn.no/_c/distribution-fixed/FINNExternalDistribution.js" defer crossorigin></script>`}`;
    }

    updated() {
        const Heart = createHeartComponent({
            userId: this.initialState.loginId,
            baseUri: this.initialState.favoritesListUrl,
            modalAttach: this.appRef.value as HTMLElement,
        });

        const isMobile = this.initialState.deviceType !== 'desktop';

        const renderActions = ({ adId, itemType }) => {
            const isFavouritableAd = Boolean(adId);

            return isFavouritableAd ? (
                <Heart
                    itemId={adId}
                    itemType={itemType}
                    isMobile={isMobile}
                    variant="heart-on-image"
                />
            ) : null;
        };

        const favoriteHeart = (itemId: string) => {
            return (
                <Heart
                    itemId={parseInt(itemId)}
                    itemType="Ad"
                    isMobile={isMobile}
                    variant="heart-on-image"
                />
            )
        };

        const props = {
            ...this.initialState,
            renderActions,
            favoriteHeart,
        };

        hasLegalBasis(this.initialState.url).then((legalBasis) => {
            ReactDOM.render(
                <RecommendationsWithFallback
                    {...props}
                    fallbackTitle={this.initialState.fallbackTitle}
                    fallbackProduct={this.initialState.fallbackProduct}
                    forceFallback={!!!legalBasis}
                />,
                this.appRef.value
            );
        });
    }
}

if (!customElements.get('data-podlet-recommend')) {
    customElements.define('data-podlet-recommend', WebComponent);
}