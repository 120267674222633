import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MessageBus } from '@podium/browser';

import {
    useExternalClickTracking,
    useInstanceId, useTrackInscreen,
} from '../../hooks';
import Tracking from '../../tracking';

const MB_CHANNEL_NATIVE_AD = 'recirc-banner-ad';

type BannerAdProps = {
    ad: import('../../types').Recommendation;
    tracking: Tracking;
    position: string;
};

let messageBus

const BannerAd: React.FC<BannerAdProps> = (props) => {
    const { tracking, ad, position } = props;
    const domRef = useRef<HTMLDivElement>(null);

    const instanceId = useInstanceId((id) => `ad-stream__banner-ad-${id}`);

    const [loadFailed, setLoadFailed] = useState(false);

    useTrackInscreen({
        adId: ad.itemId,
        ref: domRef,
        tracking,
        enabled: !loadFailed,
        pulseContext: ad?.tracking?.pulse,
    });

    useExternalClickTracking({
        adId: ad.itemId,
        ref: domRef,
        enabled: !loadFailed,
        tracking,
        pulseContext: ad?.tracking?.pulse,
    });

    const handleAdLoadFailed = useCallback(() => {
        setLoadFailed(true)
    }, [])

    useEffect(() => {
        if (!messageBus) {
            messageBus = new MessageBus();
        }

        messageBus.publish(MB_CHANNEL_NATIVE_AD, 'init', {
            instanceId,
            position,
            keywords: {
                bap_main_category: ad.content.main_category,
                bap_product_category: ad.content.prod_category,
                bap_sub_category: ad.content.sub_category,
            }
        })
        messageBus.subscribe(MB_CHANNEL_NATIVE_AD, 'load-failed', handleAdLoadFailed);

        return () => {
            if (!messageBus) {
                messageBus = new MessageBus();
            }
            messageBus.unsubscribe(
                MB_CHANNEL_NATIVE_AD,
                'load-failed',
                handleAdLoadFailed,
            );
        };
    }, [ad, handleAdLoadFailed, instanceId, position]);

    if (loadFailed) return null;
    return (
        <div aria-hidden="true" className="col-span-full mx-auto" ref={domRef}>
            <slot name={instanceId} />
        </div>
    );
};

export default BannerAd;
