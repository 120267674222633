import React from 'react';

interface ImageFormatProps {
    imageUrl: string;
    type: 'EXTERNAL' | 'LOGO' | string;
    isAbsolute?: boolean;
    imagesCdnUrl?: string;
}

//rename at some point
const ImageFormat: React.FC<ImageFormatProps> = ({
    imageUrl,
    type,
    isAbsolute = false,
    imagesCdnUrl = undefined
}) => {
    const isLogo = type === 'LOGO';

    //figure out if we need src set for performance tweaking
    const getImageSrcSet = () => {
        if (isAbsolute || type === 'EXTERNAL') {
            return imageUrl;
        }

        if (imagesCdnUrl.includes('images.tori.fi')) { //if tori
            return `https://images.beta.tori.fi/dynamic/960w/${imageUrl}`;
        }

        return `${imagesCdnUrl}/dynamic/default/${imageUrl}`;
    };

    const getImageClass = () => {
        if (isLogo) {
            return 'w-full h-full object-center object-scale-down m-auto sm:h-auto';
        }
        return 'w-full h-full object-center object-cover rounded-8 border border-bluegray-300';
    };

    return (
        <img
            alt=""
            className={getImageClass()}
            sizes="(min-width: 1300px) 303px, (min-width: 1010px) 313px, (min-width: 768px) 33vw, (min-width: 480px) 50vw, 100vw"
            src={getImageSrcSet()}
            style={{
                maxWidth: isLogo ? '65%' : '100%',
                maxHeight: isLogo ? '65%' : '100%',
            }}
        />
    );
};

export default ImageFormat;